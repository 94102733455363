<template>
  <div>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card class="p-0">
            <template class="p-0" v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h3 class="card-title p-0">
                  <b>Honorarios de éxito</b>
                </h3>
              </div>
            </template>
            <div>
              <b-button v-if="profesional" variant="success" class="float-right mt-3 mr-5" @click="exportToExcel">Exportar a Excel</b-button>
              <b-button v-if="profesional && existeHonorarios == 0" variant="info" class="float-right mt-3 mr-3" @click="liquidar()">Liquidar</b-button>
              <b-button v-if="profesional" variant="info" class="float-right mt-3 mr-3" @click="verResumen()">Ver Resumen</b-button>
            </div>
            <div class="px-5 py-3">
              
            </div>
            <div class="row px-3 ml-1 mb-4">
              <div class="col-2 px-0">
                <h4 class="mb-2"><b>Mes:</b></h4>
                <b-select v-model="mesSeleccionado" class="form-control bg-dark personalizado" :options="listaMes" text-field="mes" value-field="id" />
              </div>
              <div class="col-2 px-1">
                <h4 class="mb-2"><b>Año:</b></h4>
                <b-select v-model="anioSeleccionado" class="form-control bg-dark personalizado" :options="listaAnios" />
              </div>
               
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="profesional"
                  :options="listaProfesional"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona un profesional --"
                ></v-select>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x sucursal:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="sucursal"
                  :options="listaSucursal"
                  label="profesionalPresupuesto"
                  placeholder="-- selecciona opc. --"
                ></v-select>
              </div>
              <div class="col-1 px-1 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(2)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
            </div>
            <div>
              <div v-if="1 == 1">
                <h5 class="text-center mb-2">
                  <b class="text-danger">{{ existeHonorarios > 0 ? "Honorarios ya liquidados" : "" }}</b>
                </h5>
                <!-- table registro servicios-->
                <div id="ttos" class="px-3 mt-1">
                  <h4 class="card-title px-3">
                    <b
                      >Ttos. realizados, cancelados y evolucionados:
                      {{ listaItems.length  }}</b
                    >
                  </h4>
                  <data-table :data="listaItems" :headers="encabezadostablaJson[0]" :items-per-page="itemsPerPage" />
                </div>
                 
              </div>
            </div>
            <!-- modal add servicio -->
             
             
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import dataTable from "./TablaComponente.vue";
// import CardTable from "@/components/cardTable/CardTableV3.vue";
import * as XLSX from "xlsx";

const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    dataTable,
  },
  data() {
    return {
      listaMes: [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
      ],
      listaAnios: [],
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      dataTableInstance: null,
      listaPacientes: [],
      listaProfesional: [],
      listaSucursal: [],
      listaItemsbk: [],
      paciente: null,
      profesional: null,
      sucursal: null,
      listaItemsMaterial: [],
      listaLaboratorio: [],
      listaLaboratorioBk: [],
      listaItemsRemision: [],
      listaTipo: [{ descripcion: "Descuento" }, { descripcion: "Agregado" }],
      Registro: {},
      Periodo: {},
      otrosConceptos: [],
      pacienteManual: "",
      totalAgregados: 0,
      totalDescuentos: 0,
      totalTtosRealizados: 0,
      totalCostosMaterial: 0,
      totalCostosRemision: 0,
      totalCostosLaboratorio: 0,
      totalNetoHonorarios: 0,
      activaTablas: false,
      existeHonorarios: 0,
      //////////////*css*/`
      itemsPerPage: 6,
      currentPages: [1, 1, 1, 1, 1], // Una página para cada tabla

      encabezadostablaJson: [
        //ttos realizados
        [
          { header: "NOMBRE", key: "rut" },
          { header: "PRIMEROS-ABONOS", key: "pacienteNombre" },
          { header: "IDENTIFICACION", key: "identificacion" },
          { header: "CIERRES-ORTODONCIA", key: "prestacion" },
          { header: "IMPLANTES-MONTADOS", key: "valorPrestacionSub" },
          { header: "MONTAJES ORTODONCIA", key: "porcentaje" },

          { header: "INICIOS", key: "inicios" },
          { header: "HONORARIOS DE ÉXITO PACKS JULIO", key: "packsJulio" },
          { header: "NOTA DEBITO CONVENIOS / ALIANZAS", key: "notaDebito" },
          { header: "TOTALES", key: "totales" },
        ],
        
      ],
      listaItems: [
          {
            id: "1",
            rut: "ALAMOS", 
            identificacion: "21656311",
            pacienteNombre: "NATHALIA ARRIETA",
            prestacion: 2455,
            valorPrestacionSub: 24554,
            porcentaje: 245, 

            inicios: 545,
            packsJulio: 2455,
            notaDebito: 45542,
            totales: 24755, 
        
          },
          {
            id: "2",
            rut: "ALAMOS",
            identificacion: "21656311",
            pacienteNombre: "MARIA CAMILA BASTIDAS JIMENEZ ",
            prestacion: 8888,
            valorPrestacionSub: 888,
            porcentaje: 28845, 

            inicios: 88,
            packsJulio: 2488855,
            notaDebito: 4885542, 
            totales: 28884755, 
        
          },
          {
            id: "3",
            rut: "ALAMOS",
            identificacion: "21656311",
            pacienteNombre: "MARIA CAMILA BASTIDAS JIMENEZ ",
            prestacion: 2455,
            valorPrestacionSub: 2455,
            porcentaje: 245, 

            inicios: 545,
            packsJulio: 2455,
            notaDebito: 45542,
            totales: 24755, 
        
          },
          {
            id: "4",
            rut: "CEDRITOS",
            identificacion: "9999",
            pacienteNombre: "BARRETO URIBE JENNIFER CAROLINA",
            prestacion: 9999,
            valorPrestacionSub: 999,
            porcentaje: 99999, 

            inicios: 99,
            packsJulio: 9999,
            notaDebito: 99999999,
            totales: 9999, 
        
          },
        ],
      PorcentajeSucursal: 0,
      TotalHonorarios: 0,
      CostosPorDefecto: [],
    };
  },
  async mounted() {
    const anioActual = new Date().getFullYear();
    const anioInicio = anioActual - 2;
    const listaAnios = [];

    for (let anio = anioInicio; anio <= anioActual; anio++) {
      listaAnios.push(anio);
    }
    this.listaAnios = listaAnios;
    // this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    
    this.mesSeleccionado = new Date().getMonth();
    this.anioSeleccionado = new Date().getFullYear();
    this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(this.anioSeleccionado, this.mesSeleccionado);
    // core.index();
    // this.getProfesionalesYSucursales();
    // this.obtenerCostosPorDefecto();
  },
  methods: {
    async obtenerCostosPorDefecto() {
      try {
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ObtenerCostosPorDefecto",
        });
        this.CostosPorDefecto = res;
        console.log("this.CostosPorDefecto", this.CostosPorDefecto);
      } catch (error) {
        this.CostosPorDefecto = [];
      }
    },
    async obtenerPorcentajeSucursal() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ObtenerPorcentajeSucursal/" + this.sucursal,
        });
        this.PorcentajeSucursal = res;
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.PorcentajeSucursal = 0;
      }
    },
    async getProfesionalesYSucursales() {
      try {
        // this.listaItems = [];
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaProfesionales/",
        });
        this.listaProfesional = await res.map((x) => {
          return x.nombreProfesional;
        }, 0);

        let resSucursales = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaSucursales/",
        });
        this.listaSucursal = await resSucursales.map((x) => {
          return x.sucursal;
        }, 0);
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },

    updateTables() {
      this.currentPages = [1, 1, 1, 1, 1];
    },
    cadenaMes() {
      let numero = this.mesSeleccionado;
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      // Verificar que el número esté en el rango válido (1-12)
      if (numero < 1 || numero > 12) {
        return "Número inválido. Debe estar entre 1 y 12.";
      }

      // Restar 1 para obtener el índice correcto del mes
      return meses[numero - 1];
    },
    async calculoTotales() {
      try {
        await this.obtenerPorcentajeSucursal();
        this.totalAgregados = 0;
        this.totalDescuentos = 0;
        //// CALCULANDO AGREGADOS Y DESCUENTOS DE OTROS CONCEPTOS
        let listaAgregados = this.otrosConceptos.filter((x) => x.tipo != "Descuento");
        let totalAgregados = listaAgregados.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);
        let listaDescuentos = this.otrosConceptos.filter((x) => x.tipo == "Descuento");
        let totalDescuentos = listaDescuentos.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);
        //CALCULANDO AGREGADOS Y DESCUENTOS DE LISTAITEMS
        let listaAgregadosttos = this.otrosConceptos.filter((x) => x.tipo != "Descuento");
        let totalAgregadosttos = listaAgregadosttos.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);

        let listaDescuentosttos = this.otrosConceptos.filter((x) => x.tipo == "Descuento");
        let totalDescuentosttos = listaDescuentosttos.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);

        this.totalDescuentos = Number(totalDescuentosttos) + Number(totalDescuentos);
        this.totalAgregados = Number(totalAgregadosttos) + Number(totalAgregados);
        this.totalNetoHonorarios = Number(this.totalAgregados) - Number(this.totalDescuentos);

        ///calculos totales///////////////////////////////////////////

        this.totalTtosRealizados = this.listaItems.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valorPrestacionSub);
          return accumulator + valueToAdd;
        }, 0);
        // this.totalTtosRealizados = this.totalTtosRealizados.toFixed(2);
        this.totalCostosMaterial = this.listaItemsMaterial.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valorPrestacion);
          return accumulator + valueToAdd;
        }, 0);

        this.totalCostosRemision = this.listaItemsRemision.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valorPrestacion);
          return accumulator + valueToAdd;
        }, 0);

        this.totalCostosLaboratorio = this.listaLaboratorio.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);
        /// CALCULANDO TOTAL NETO
        this.totalNetoHonorarios = this.totalNetoHonorarios + this.totalTtosRealizados - this.totalCostosMaterial - this.totalCostosRemision - this.totalCostosLaboratorio;
        this.TotalHonorarios = (this.totalNetoHonorarios * this.PorcentajeSucursal) / 100;
      } catch (error) {
        console.log("error", error);
      }
    },
    async liquidar() {
      try {
        Swal.fire({
          title: "¿Quieres Liquidar?",
          text: "Después de liquidar no podrás modificar datos!",
          icon: "warning",
          showCancelButton: true,
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            this.$isLoading(true);
            //ingresando ttos realizados etc
            console.log(result.value);
            let dataALiquidar = this.listaItems.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.rut,
              PacienteNombre: x.pacienteNombre,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valorPrestacionSub,
              Tipo: "Agregado",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Ttos. realizados, cancelados y evolucionados",
              Descripcion: x.prestacion,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidar,
            });
            //material especial
            let dataALiquidarMaterial = this.listaItemsMaterial.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.idPaciente,
              PacienteNombre: x.paciente,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valor,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos material especial",
              Descripcion: x.prestacionArea,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarMaterial,
            });

            //Costos de remision
            let dataALiquidarCostosRemision = this.listaItemsRemision.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.idPaciente,
              PacienteNombre: x.paciente,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valor,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos de remisión",
              Descripcion: x.prestacionArea,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarCostosRemision,
            });

            //Costos de laboratorio
            let dataALiquidarCostosDeLaboratorio = this.listaLaboratorio.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.rut,
              PacienteNombre: x.pacienteNombre,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valorPrestacion,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos de remisión",
              Descripcion: x.prestacion,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarCostosDeLaboratorio,
            });

            this.$isLoading(false);
            await this.VerificaLiquidacionHonorarios();
            Swal.fire("Muy Bien!", "Liquidación generada correctamente!", "success").then(() => {
              // this.initialEvents = [];
              // this.modal = false;
              // this.$router.replace("/calendar");
              // window.location.reload();
            });
          }
        });
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    verResumen() {
      this.$bvModal.show("resumen");
    },
    ingresarPaciente() {
      try {
        Swal.fire({
          title: "Ingresa nombre del paciente",
          input: "text",
          inputPlaceholder: "paciente",
          showCancelButton: true,
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          inputValidator: (value) => {
            if (!value) {
              return "Debes escribir algo!";
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // Aquí puedes utilizar el texto ingresado por el usuario
            // Swal.fire(`Texto ingresado: ${result.value}`);
            this.pacienteManual = result.value;
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async saveData() {
      try {
        this.Registro.profesionalNombre = this.profesional;
        // this.Registro.Rut =
        this.Registro.PacienteNombre = this.pacienteManual != "" ? this.pacienteManual : this.paciente;
        this.Registro.Mes = this.mesSeleccionado;
        this.Registro.Anio = Number(this.anioSeleccionado);
        // this.Registro.Valor =
        // this.Registro.Tipo =

        this.Registro.Fecha = this.$moment.tz(this.Periodo.ultimoDia, "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.Registro.Sucursal = this.sucursal;
        console.log(this.Registro);
        await this.$store.dispatch("denti_post", {
          path: "Prestaciones/CrearOtros",
          data: this.Registro,
        });
        this.obtenerOtrosConceptos();
        await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
        this.showToast("success", "Datos guardados correctamente");
        this.$bvModal.hide("nuevoServicio");
      } catch (error) {
        this.showToast("Error", "Error, intentalo de nuevo");
        console.log("error", error);
      }
    },
    verModal() {
      this.$bvModal.show("nuevoServicio");
    },
    exportToExcel() {
      // Crear las hojas de datos de cada array con títulos y sumatorias
      const sheet1 = this.createSheetWithSum(this.listaItems, "Prestaciones", "valorPrestacion");
      const sheet2 = this.createSheetWithSum(this.listaItemsMaterial, "Material Especial", "valor");
      const sheet3 = this.createSheetWithSum(this.listaItemsRemision, "Costos de remisión", "valorPrestacion");
      const sheet4 = this.createSheetWithSum(this.listaLaboratorio, "Costos Laboratorio", "valorPrestacion");
      const sheet5 = this.createSheetWithSum(this.otrosConceptos, "Otros conceptos", "valor");

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Agregar cada hoja de trabajo con el nombre deseado
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet1), "Prestaciones");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet2), "Material Especial");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet3), "Costos de Remisión");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet4), "Costos Laboratorio");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet5), "Otros conceptos");

      // Exportar el archivo Excel
      // XLSX.writeFile(wb, 'datos_separados.xlsx');
      XLSX.writeFile(wb, "Liquidación-" + this.profesional + "-" + this.mesSeleccionado + "-" + this.anioSeleccionado + "-" + this.sucursal + ".xlsx");
    },
    // Función para crear una hoja con sumatoria
    createSheetWithSum(data, title, sumColumn) {
      // Verificar si el array de datos está vacío
      if (!data || data.length === 0) {
        console.warn(`El array ${title} está vacío o no está definido.`);
        return [[title], ["No hay datos disponibles"]]; // Retorna un mensaje si el array está vacío
      }

      const headers = Object.keys(data[0]); // Obtener encabezados de las claves del array
      const sheetData = [];

      // Agregar los encabezados de las columnas
      sheetData.push(headers);

      // Agregar los datos del array
      data.forEach((item) => {
        sheetData.push(headers.map((key) => item[key]));
      });

      // Calcular y agregar la fila de sumatoria
      const sumRow = headers.map((key) => (key === sumColumn ? this.sumColumn(data, key) : ""));
      sheetData.push(["Total", ...sumRow.slice(1)]); // Agregar 'Total' y la sumatoria

      return sheetData;
    },
    // Función para calcular la sumatoria de una columna específica
    sumColumn(data, column) {
      return data.reduce((sum, item) => {
        const value = Number(item[column]); // Convertir a número
        return sum + (isNaN(value) ? 0 : value); // Sumar solo si es un número válido
      }, 0);
    },
    async filtrar() {
      try {
        // if (item == 1) {
        //   this.paciente = null;
        //   this.profesional = null;
        //   this.sucursal = null;
        //   ////////
        //   this.listaLaboratorio = [];
        //   this.listaItemsMaterial = [];
        //   this.otrosConceptos = [];
        //   this.listaItemsRemision = [];
        //   this.existeHonorarios = 0;
        //   this.listaItemsbk = [];

        //   // console.log("filtrando", item, this.nombre);
        //   this.$isLoading(true);
        //   //filtrar x mes y año
        //   let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        //   let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        //   this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        //   await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        //   this.$isLoading(false);
        // }
        // if (item == 2) {
        this.listaLaboratorio = [];
        this.listaItemsMaterial = [];
        this.otrosConceptos = [];
        this.listaItemsRemision = [];
        this.existeHonorarios = 0;
        // this.updateTables();
        ////////////////////////////////////////////////

        this.paciente = null;
        // this.profesional = null;
        // this.sucursal = null;
        ////////
        this.listaItemsbk = [];
        this.listaItems = [];
        let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        ///////////////////////////////////////////////
        this.activaTablas = true;
        // console.log(this.paciente);
        if (this.paciente != null && this.profesional == null && this.sucursal == null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal == null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal != null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
        }

        if (this.paciente == null && this.profesional != null && this.sucursal == null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional);
        }
        if (this.paciente == null && this.profesional != null && this.sucursal != null) {
          // this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
          //problema: no existe rut del profesional en prestaciones
          try {
            this.$isLoading(true);

            //filtrando materiales si ha seleccionado profesional y sucursal
            let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
            let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
            this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);

            // material especial
            await this.obtenerMateriales(this.Periodo.primerDia, this.Periodo.ultimoDia, "000", this.sucursal);
            // console.log("listaItemsMaterial", this.listaItemsMaterial);
            //let idProfesional = this.listaItems.filter((x) => x.profesionalNombre == this.profesional);

            let listaItemsMaterial = this.listaItemsMaterial.filter((x) => {
              const normalize = (nombre) => nombre.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");

              const nombreProfesional = normalize(this.profesional);
              const nombreItem = normalize(x.profesional);
              // console.log("profesional data 0", nombreProfesional);
              // console.log("profesional data 1", nombreItem);
              // Función para comparar dos nombres sin importar el orden
              const sonIguales = (n1, n2) => {
                const arr1 = n1.split(" ").sort();
                const arr2 = n2.split(" ").sort();
                return JSON.stringify(arr1) === JSON.stringify(arr2);
              };
              return sonIguales(nombreItem, nombreProfesional);
            });
            // obtener costo por defecto material especial y costos de remisión por defecto
            let costoMaterialEspecialPorDefecto = this.CostosPorDefecto.filter((x) => x.categoria == "COSTOS DE MATERIAL ESPECIAL")[0].valorPorDefecto;
            let costoRemisionPorDefecto = this.CostosPorDefecto.filter((x) => x.categoria == "COSTOS DE REMISION")[0].valorPorDefecto;
            // for await (let itm of listaItemsMaterial) {
            //   itm.valor = parseFloat(itm.costoUnitarioProm) * 1.2;
            // }
            console.log("this.listaItemsMaterial", listaItemsMaterial);
            listaItemsMaterial = listaItemsMaterial.map((x) => {
              return {
                idPaciente: x.idPaciente,
                paciente: x.paciente,
                prestacionArea: x.prestacionArea,
                valorPrestacion: costoMaterialEspecialPorDefecto, //x.valorPrestacion,
                fecha: x.fecha,
              };
            });
            this.listaItemsMaterial = listaItemsMaterial;

            //costos de remision
            this.listaItemsRemision = listaItemsMaterial.map((item) => ({
              ...item,
              valorPrestacion: costoRemisionPorDefecto,
            }));
            //this.$forceUpdate();
            // console.log("this.listaItemsRemision", this.listaItemsRemision);
            // obtener data laboratorio
            await this.obtenerLaboratorio(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);

            let listaLaboratorio = this.listaLaboratorioBk.filter((x) => {
              const normalize2 = (nombre2) => nombre2.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
              // console.log("EspecialistaOcTrabLaborator", x.especialistaOcTrabLaborator);
              const nombreProfesional2 = normalize2(this.profesional);
              // const nombreItem2 = normalize2(x.profesionalNombre.split("-")[1]);
              const nombreItem2 = normalize2(x.profesionalNombre);
              // console.log("EspecialistaOcTrabLaborator data 0", nombreProfesional);
              // console.log("EspecialistaOcTrabLaborator data 1", nombreItem2);
              // Función para comparar dos nombres sin importar el orden
              const sonIguales2 = (n12, n22) => {
                const arr12 = n12.split(" ").sort();
                const arr22 = n22.split(" ").sort();
                return JSON.stringify(arr12) === JSON.stringify(arr22);
              };

              return sonIguales2(nombreItem2, nombreProfesional2);
            });
            this.listaLaboratorio = listaLaboratorio;
            // console.log("EspecialistaOcTrabLaborator", listaLaboratorio);

            // obtener otros conceptos
            await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
            await this.VerificaLiquidacionHonorarios();

            // this.totalAgregados = 0;
            // this.totalDescuentos = 0;
            // this.otrosConceptos.forEach((item) => {
            //   if (item.tipo === "Descuento") {
            //     this.totalDescuentos += item.valor;
            //   } else if (item.tipo === "Agregado") {
            //     this.totalAgregados += item.valor;
            //   }
            // });
            ///calculos totales

            // this.totalTtosRealizados = this.listaItems
            //   .reduce((accumulator, currentItem) => {
            //     const valueToAdd = Number(currentItem.porcentaje) > 0 ? parseFloat(currentItem.valorPrestacionSub) : parseFloat(currentItem.valorPrestacion);
            //     return accumulator + valueToAdd;
            //   }, 0)
            //   .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

            // this.totalCostosMaterial = this.listaItemsMaterial
            //   .reduce((accumulator, currentItem) => {
            //     const valueToAdd = parseFloat(currentItem.valor);
            //     return accumulator + valueToAdd;
            //   }, 0)
            //   .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            // this.totalCostosRemision = this.listaItemsRemision
            //   .reduce((accumulator, currentItem) => {
            //     const valueToAdd = parseFloat(currentItem.valorPrestacion);
            //     return accumulator + valueToAdd;
            //   }, 0)
            //   .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            // this.totalCostosLaboratorio = this.listaLaboratorio
            //   .reduce((accumulator, currentItem) => {
            //     const valueToAdd = parseFloat(currentItem.valorPrestacion);
            //     return accumulator + valueToAdd;
            //   }, 0)
            //   .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            // let sumatorias = parseFloat(this.totalTtosRealizados) + parseFloat(this.totalAgregados);
            // let descuentos = parseFloat(this.totalCostosLaboratorio) + parseFloat(this.totalCostosRemision) + parseFloat(this.totalDescuentos);
            // console.log("datosssss", sumatorias, descuentos);
            // this.totalNetoHonorarios = 12354322; //sumatorias - descuentos;

            this.updateTables();
            this.$forceUpdate();
            this.$isLoading(false);
          } catch (error) {
            this.$isLoading(false);
          }
        }

        if (this.paciente == null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.sucursalPpto == this.sucursal);
        }
        if (this.paciente != null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.sucursalPpto == this.sucursal);
        }
        // }
        await this.calculoTotales();
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async VerificaLiquidacionHonorarios() {
      this.existeHonorarios = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/VerificaLiquidacionHonorarios/" + this.mesSeleccionado + "/" + this.anioSeleccionado + "/" + this.profesional,
      });
      // console.log("otros", res);
    },
    async obtenerOtrosConceptos(primerDia, ultimoDia, sucursal) {
      this.otrosConceptos = [];
      let res = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/ListaOtros/" + primerDia + "/" + ultimoDia + "/" + this.profesional + "/" + sucursal,
      });
      console.log("otros", res);
      res = res.map((x) => {
        return {
          rut: x.rut,
          pacienteNombre: x.pacienteNombre,
          concepto: x.concepto,
          descripcion: x.descripcion,
          tipo: x.tipo,
          valor: x.valor,
        };
      });
      this.otrosConceptos = res;
    },
    async obtenerLaboratorio(primerDia, ultimoDia, sucursal) {
      this.listaLaboratorio = [];
      this.listaLaboratorioBk = this.listaLaboratorio;
      let res = await this.$store.dispatch("denti_get", {
        path: "SIE_Laboratorio/ListaLaboratorio/" + primerDia + "/" + ultimoDia + "/" + sucursal,
      });
      console.log("res dentisalud ", res);
      this.listaLaboratorio = res.length > 0 ? res : [];
      this.listaLaboratorioBk = this.listaLaboratorio;
    },
    async obtenerMateriales(primerDia, ultimoDia, rutProfesional, Sucursal) {
      this.listaItemsMaterial = [];
      //buscar el idprofesional en this.listaItems

      let res = await this.$store.dispatch("denti_get", {
        path: "VMaterialesEspeciales/ListVMaterialesEspeciales/" + primerDia + "/" + ultimoDia + "/" + Sucursal + "/" + rutProfesional,
      });
      console.log("res dentisalud ", res);
      this.listaItemsMaterial = res.length > 0 ? res : [];
    },
    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },
    async getData(primerDia, ultimoDia) {
      try {
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaPrestaciones/" + primerDia + "/" + ultimoDia + "/" + this.profesional + "/" + this.sucursal,
        });

        // let listaItems = res.length > 0 ? res : [];
        // console.log("listaItems-------->", listaItems);
        //if (listaItems.length > 0) {
        let listaItems2 = await res.map((item) => {
          return {
            // ...item,
            rut: item.rut,
            pacienteNombre: item.pacienteNombre,
            prestacion: item.prestacion,
            valorPrestacionSub: Number(item.valorPrestacion) - Number(item.valorPrestacion) * Number(item.porcentaje),
            porcentaje: item.porcentaje,
          };
        });
        //}

        this.listaItems = listaItems2;
        console.log("this.listaItems2-------->", this.listaItems);
        this.listaItemsbk = res;

        // this.listaPacientes = res.map((p) => p.pacienteNombre).filter((nombre, index, self) => self.indexOf(nombre) === index);

        // console.log("listapacientes", this.listaPacientes);

        // this.listaPacientes.sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // this.listaProfesional = res.map((pa) => pa.profesionalNombre).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        // this.listaProfesional.sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // this.listaSucursal = res.map((pa) => pa.sucursalPpto).filter((nombrep, indexp, selfp) => selfp.indexOf(nombrep) === indexp);
        // this.listaSucursal.sort((a, b) => {
        //   if (a < b) return -1;
        //   if (a > b) return 1;
        //   return 0;
        // });

        // console.log("pacientes ", this.listaPacientes);
        // console.log("list registroServicios...", res);
        // if (res.success) {
        //   this.listaItems = res.data.map((service) => {
        //     return {
        //       ...service,
        //       placa: this.getPlacaVehiculo(service.placaId),
        //       estado: service.fechaFin ? true : false,
        //     };
        //   });
        //   // console.log(this.listaItems);
        // } else {
        //   this.listaItems = [];
        // }
        // this.$isLoading(false);

        // this.$nextTick(function () {
        //   if (!window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //     window.$("#datatable_registroServicios").DataTable();
        //   }
        // });

        // core.index();

        // if (window.$.fn.DataTable.isDataTable("#datatable_registroServicios")) {
        //   window.$("#datatable_registroServicios").DataTable().destroy();
        // }
        // this.dataTableInstance = window.$("#datatable_registroServicios").DataTable({
        //   language: {
        //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        //   },
        // });
        // this.$forceUpdate();
      } catch (error) {
        this.$isLoading(false);

        this.listaItems = [];
        console.log("err dentisalud ", error);
      }
    },
  },
};
</script>

<style scoped>
.custom-popup {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 1em;
  font-size: 1rem !important;
}

.custom-title {
  color: #333;
  font-size: 0.5rem !important;
  font-weight: bold;
}

.custom-input {
  border: 1px solid #ddd;
  padding: 0.5em;
  border-radius: 5px;
}

.custom-confirm-button {
  background-color: #4caf50;
  color: white;
  padding: 0.5em 1.5em;
}

.custom-cancel-button {
  background-color: #f44336;
  color: white;
}
</style>
